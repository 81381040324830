var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "background" }, [
    _c(
      "div",
      { staticClass: "login-container" },
      [
        _c("el-form", { staticClass: "card-box form" }, [
          _c("h1", [_vm._v("This project can't be found.")]),
        ]),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "copyright" },
      [
        _vm._v(" Made with "),
        _c("heart", { attrs: { width: 12 } }),
        _vm._v(" by CodeFish Studio "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }